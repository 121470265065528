import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  EditorPreview,
  Features,
  HowItWorks,
  Footer,
  Header,
  SEOHead,
  Hero,
  Modal,
  Banner,
  GetStarted,
  Divider,
} from "../components";
import { FormSignUp } from "../components/FormSignUp";

export default function IndexPage() {
  const intl = useIntl();
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <SEOHead title={intl.formatMessage({ id: "page.home.title" })} />

      <Banner.Integration />
      <Divider thickness="1" />

      <Header fixed={false} />

      <main className="flex-grow">
        <Hero.Standard
          className="px-4 sm:px-6 pt-24 md:pt-20 pb-12 md:pb-16"
          showTypingEffect
          title={intl.formatMessage({ id: "page.home.heroTitle" })}
          description={intl.formatMessage({ id: "page.home.heroDescription" })}
          actions={
            <>
              <button
                className="btn btn-primary btn-lg w-full mb-4 sm:w-auto sm:mb-0 px-12"
                aria-controls="modal"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSignUpModalOpen(true);
                }}
              >
                {intl.formatMessage({ id: "startTrial" })}
              </button>
            </>
          }
        />
        <EditorPreview
          title={intl.formatMessage({ id: "page.home.demoTitle" })}
          description={intl.formatMessage({ id: "page.home.demoSubtitle" })}
        />
        <Features />
        <HowItWorks />
        <Divider thickness="2" />
        <GetStarted />
        <Divider thickness="2" />
      </main>

      <Modal
        id="modal-sign-up-homepage"
        ariaLabel="modal-headline"
        show={signUpModalOpen}
        handleClose={() => setSignUpModalOpen(false)}
        className="w-full"
      >
        <div className="relative bg-white p-5 rounded-lg max-w-lg mx-auto">
          <Modal.Close handleClose={() => setSignUpModalOpen(false)} />
          <FormSignUp />
        </div>
      </Modal>

      <Footer />
    </div>
  );
}
